<template>
  <v-card color="white" class="pa-8">
    <v-card-text class="px-0">
      <div class="bsat__title">{{ $t("title") }}</div>
      <div>
        <i18n path="objective.text" tag="div" class="bsat__paragraph">
          <template #title>
            <strong>{{ $t("objective.title") }}</strong>
          </template>
        </i18n>
      </div>
      <div class="mt-8">
        <v-tabs
          v-model="tab"
          class="bsat__tabs"
          background-color="transparent"
          slider-color="dark"
          color="dark"
          show-arrows
          center-active
        >
          <v-tab
            v-for="(item, i) in tabs"
            :key="i"
            v-text="$t(item.tab)"
            class="mr-4 mr-sm-8"
            :style="
              $vuetify.breakpoint.xsOnly
                ? 'min-width: 90px'
                : 'min-width: 160px'
            "
          >
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <!-- ********** POLICY ANALYSIS TAB ******* -->
          <v-tab-item>
            <v-card flat color="white" class="bg-white">
              <v-card-text class="px-0">
                <div>
                  <i18n
                    path="policyAnalysis.objective.text"
                    tag="div"
                    class="bsat__paragraph"
                  >
                    <template #title>
                      <strong>{{
                        $t("policyAnalysis.objective.title")
                      }}</strong>
                    </template>

                    <template #ref1>
                      <span class="font-italics" style="font-size: 12px">{{
                        $t("policyAnalysis.objective.ref1")
                      }}</span>
                    </template>

                    <template #ref2>
                      <span class="font-italics" style="font-size: 12px">{{
                        $t("policyAnalysis.objective.ref2")
                      }}</span>
                    </template>
                  </i18n>
                </div>

                <i18n
                  path="policyAnalysis.objective4.text"
                  tag="div"
                  class="bsat__paragraph"
                >
                  <template #strong>
                    <strong>{{
                      $t("policyAnalysis.objective4.strong")
                    }}</strong>
                  </template>
                </i18n>
                <!-- <div class="bsat__paragraph">
                  {{ $t("policyAnalysis.objective4") }}
                </div> -->
                <div
                  class="bsat__paragraph font-italics"
                  style="font-size: 11px"
                >
                  {{ $t("policyAnalysis.objective2") }}
                </div>
                <div
                  class="bsat__paragraph font-italics"
                  style="font-size: 11px"
                >
                  {{ $t("policyAnalysis.objective3") }}
                </div>
                <div>
                  <i18n
                    path="policyAnalysis.action.text"
                    tag="div"
                    class="bsat__paragraph"
                  >
                    <template #title>
                      <strong>{{ $t("policyAnalysis.action.title") }}</strong>
                    </template>
                  </i18n>
                </div>
                <div class="bsat__paragraph">
                  <strong>{{ $t("policyAnalysis.materialAvailable") }}</strong>
                </div>
                <div class="bsat__paragraph">
                  {{ $t("policyAnalysis.firstBtnText") }}
                </div>
                <a :href="policyAnalysisFile" target="_blank" download>
                  <v-btn
                    depressed
                    width="150"
                    height="26"
                    class="bsat__btn"
                    dark
                    color="dark"
                    >{{ $t("download") }}</v-btn
                  >
                </a>
                <!-- <div class="bsat__paragraph">
                  {{ $t("policyAnalysis.secondBtnText") }}
                </div>

                <a :href="policyAnalysisFile2" target="_blank" download>
                  <v-btn
                    depressed
                    width="150"
                    height="26"
                    class="bsat__btn"
                    dark
                    color="dark"
                    >{{ $t("download") }}</v-btn
                  >
                </a> -->
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- ********** TECHNICAL ANALYSIS TAB ******* -->
          <v-tab-item>
            <v-card flat color="white">
              <v-card-text class="px-0">
                <div>
                  <i18n
                    path="technicalAnalysis.objective.text"
                    tag="div"
                    class="bsat__paragraph"
                  >
                    <template #title>
                      <strong>{{
                        $t("technicalAnalysis.objective.title")
                      }}</strong>
                    </template>

                    <template #strong1>
                      <strong>{{
                        $t("technicalAnalysis.objective.strong1")
                      }}</strong>
                    </template>

                    <template #strong2>
                      <strong>{{
                        $t("technicalAnalysis.objective.strong2")
                      }}</strong>
                    </template>
                  </i18n>
                </div>
                <div class="bsat__paragraph">
                  <strong>{{ $t("technicalAnalysis.objective2") }}</strong>
                </div>
                <div>
                  <ul>
                    <li class="bsat__paragraph">
                      <strong>{{ $t("technicalAnalysis.bullet1") }}</strong>
                    </li>
                    <li class="bsat__paragraph">
                      <strong>{{ $t("technicalAnalysis.bullet2") }}</strong>
                    </li>
                  </ul>
                </div>
                <div class="bsat__paragraph">
                  {{ $t("technicalAnalysis.objective3") }}
                </div>

                <div>
                  <i18n
                    path="technicalAnalysis.action.text"
                    tag="div"
                    class="bsat__paragraph"
                  >
                    <template #title>
                      <strong>{{
                        $t("technicalAnalysis.action.title")
                      }}</strong>
                    </template>
                  </i18n>
                </div>

                <div>
                  <i18n
                    path="technicalAnalysis.material.text"
                    tag="div"
                    class="bsat__paragraph"
                  >
                    <template #title>
                      <strong>{{
                        $t("technicalAnalysis.material.title")
                      }}</strong>
                    </template>
                    <template #link>
                      <a :href="materialLink" target="_blank">{{
                        $t("technicalAnalysis.material.link")
                      }}</a>
                    </template>
                  </i18n>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- ********** SOCIAL & ENVIRONMENTAL ANALYSIS TAB ******* -->
          <v-tab-item>
            <v-card flat color="white">
              <v-card-text class="px-0">
                <div>
                  <i18n
                    path="envAnalysis.objective.text"
                    tag="div"
                    class="bsat__paragraph"
                  >
                    <template #title>
                      <strong>{{ $t("envAnalysis.objective.title") }}</strong>
                    </template>
                    <template #strong>
                      <strong>{{ $t("envAnalysis.objective.strong") }}</strong>
                    </template>
                  </i18n>
                </div>

                <div class="bsat__paragraph">
                  {{ $t("envAnalysis.objective2") }}
                </div>

                <i18n
                  path="envAnalysis.action.text"
                  tag="div"
                  class="bsat__paragraph"
                >
                  <template #title>
                    <strong>{{ $t("envAnalysis.action.title") }}</strong>
                  </template>
                </i18n>

                <i18n
                  path="envAnalysis.material.text"
                  tag="div"
                  class="bsat__paragraph"
                >
                  <template #title>
                    <strong>{{ $t("envAnalysis.material.title") }}</strong>
                  </template>
                </i18n>

                <a :href="envAnalysisFile1" target="_blank" download>
                  <v-btn
                    depressed
                    width="150"
                    height="26"
                    class="bsat__btn"
                    dark
                    color="dark"
                    >{{ $t("download") }}</v-btn
                  >
                </a>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <i18n
        class="mt-8"
        tag="div"
        path="downloadAll.text"
        style="font-family: Montserrat"
      >
        <template #link>
          <a class="dark-text mt-8" :href="zip" target="_blank" download>
            {{ $t("downloadAll.link") }}
          </a>
        </template>
      </i18n>
    </v-card-text>
  </v-card>
</template>

<i18n>
{
  "en": {
    "downloadAll": {
      "link": "click here",
      "text": "*To download all the materials of Phase 1 as a zip file please {link}"
    },
    "envAnalysis": {
      "action": {
        "text": "{title} Fill out the following questionnaire",
        "title": "Action:"
      },
      "material": {
        "text": "{title} Social and Environmental checklist.",
        "title": "Material available:"
      },
      "objective": {
        "text": "{title} To assess {strong}. This exercise will complement previous analysis.",
        "strong": "environmental and social aspects in your region",
        "title": "Objective:"
      },
      "objective2": "After completing the table, you will gather fruitful information to improve the analysis in the definition of the Bioeconomy strategy.",
      "tabTitle": "Social and Environmental analysis"
    },
    "objParagraph": "",
    "objective": {
      "text": "{title} The second step consists of evaluating and analysing the bioeconomy ecosystem in your region and its  potential towards updating Bioeconomy Strategy or setting up a new one. This analysis is done against a series of indicators considering: political, technical, and social and environmental elements.",
      "title": "Objective:"
    },
    "policyAnalysis": {
      "action": {
        "text": "{title} Mark with an X those factors that you have already considered in your region.",
        "title": "Action: "
      },
      "firstBtnText": "Policy, incentives and public initiatives checklist for bioeconomy development.",
      "materialAvailable": "Material Available: ",
      "objective": {
        "text": "{title} To assess the current status of the  policy framework in your region linked to the field of bioeconomy. You can use a “checklist”, which summarizes the main indicators set by EC(1) and FAO(2) for the development of Bioeconomy strategies.",
        "title": "Objective:",
        "ref1": "(1)",
        "ref2": "(2)"
      },
      "objective2": "(1) “A sustainable Bioeconomy for Europe: strengthening the connection between economy, society and the environment (Updated Bioeconomy Strategy)” from European Commission; ",
      "objective3": "(2) “Indicators to monitor and evaluate the sustainability of bioeconomy, Overview and a proposed way forward” from FAO",
      "objective4": {
        "text": "As a result, you will get a {strong} following a holistic approach towards bioeconomy. Empty fields will need to be addressed in the next steps of the methodology towards a more mature Bioeconomy strategy. This table will summarise the related policies and incentives which need to be aligned and harmonised. Thus, it will help you to avoid silos when overseeing related bioeconomy supportive policies in the upcoming steps, as well as having a common understanding of all related initiatives to coordinate at policy level.",
        "strong": "general bioeconomy assessment from a policy perspective"
      },
      "secondBtnText": "Template to gather general information of the region in the field of bioeconomy",
      "tabTitle": "Policy analysis"
    },
    "technicalAnalysis": {
      "action": {
        "text": "{title} Access this site and carry out your assessment.",
        "title": "Action: "
      },
      "bullet1": "Two spider diagrams (one related to biomass and the other one to waste).",
      "bullet2": "Two reports with recommendations (one related to biomass and another one to waste). Both documents contain recommendations automatically generated by the responses filled in the survey.",
      "material": {
        "link": "Self-Assessment Tool",
        "text": "{title} SAT {link}",
        "title": "Material available:"
      },
      "objective": {
        "strong1": "SAT ‘Self-Assessment Tool’",
        "strong2": "DG GROWTH of the EC",
        "text": "{title}  To assess the investment readiness level in a region concerning the use of alternative raw materials to replace equivalent fossil-based products to produce new bioproducts. This assessment is done by an open tool called {strong1} available at the webpage of {strong2}. The Self-Assessment Tool was launched to sustain sustainable chemicals. The bioeconomy definition welcomes a broader definition and a larger number of value chains. However, the factors used in this tool of DG GROWTH is consistent and aligned with the POWER4BIO indicators and addresses the same principals. Therefore, the information used by the applicants of this tool is applicable to the bioeconomy strategy definition. Also, the information (recommendations) generated automatically by that tool will enrich the regional discussions for the sake of the bioeconomy strategy definition. It consists of two questionnaires, which include up to 60/70 questions related to two types of feedstock: (1) biomass from agriculture and (2) forestry and waste from farms, municipalities, sewage sludge, agri-food industry and paper & pulp industry. ",
        "title": "Objective:"
      },
      "objective2": "You will obtain four reports as a result of your assessment.",
      "objective3": "The spider diagram and the report per feedstock (two in total) provide you with a very useful information for the next steps of the methodology. Also, the process will require the coordination with likely more than one unit/department/policy maker expert. Thus, through collecting the required information, you are already teaming up with colleagues and experts who will be instrumental in other steps ahead. ",
      "tabTitle": "Technical analysis"
    },
    "title": "Step 2: Assessment of the Bioeconomy status of the region"
  }
}
</i18n>

<script>
export default {
  name: "Phase1Step2",
  data() {
    return {
      tab: 0,
      tabs: [
        { tab: "policyAnalysis.tabTitle" },
        { tab: "technicalAnalysis.tabTitle" },
        { tab: "envAnalysis.tabTitle" }
      ],
      policyAnalysisFile: require("@/assets/files/1_1_2_1_Policy_incentives_and_public_initiatives_checklist_for_bioeconomy_development.docx")
        .default,
      envAnalysisFile1: require("@/assets/files/1_1_2_3_Social_and_Environmental_check_list.docx")
        .default,
      materialLink: "https://ec.europa.eu/growth/tools-databases/escss_en",
      zip: require("@/assets/files/Phase1_Files.zip").default
    };
  },
  computed: {}
};
</script>

<style lang="scss" scoped>
@import "@/styles/main";
</style>
